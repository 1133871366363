body{
	max-width: 100%;
	overflow-x: hidden;
	background-color: #f1f1f2;
	color: #333333;
	font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
}
@media(min-width: 1200px){
	.container{
		width: 990px;
		max-width: 990px;
	}
}
.left-sidebar{
	width: 30%;
	width: calc(30% - 30px);
	margin-right: 30px;
}
.right{
	width: 70%;
}
.wrap-page{
	background-color: #fff;
	padding: 10px 15px;
	padding-left: 0px;
	width: 100%;
	display: inline-block;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}
img{
	max-width: 100%;
}
a{
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
// mobile
@media (max-width: 767px){
	body{
		background-color: #fff;
	}
	.wrap-page{
		box-shadow: none;
		padding: 0px;
	}
	header {
	    border-bottom-color: white; //rgb(255, 255, 255)
	}
}

//table
@media (min-width: 768px){
	header {
	    border-bottom-color: red; //rgb(255, 0, 0)
	}
}
//computer
@media (min-width: 992px){
	header {
	    border-bottom-color: yellow; //rgb(255, 255, 0)
	}
}
@media(max-width: 767px){
	.left-sidebar{
		width: 100%;
		width: calc(100% - 0px);
		margin-right: 0px;
		order: 2;
		display: none;
	}
	.right{
		width: 100%;
		order: 1;
	}
	@media(min-width: 576px){

	}
}
// .all-detail{
	img{
		height: auto !important;
		max-width: 100% !important;
	}
// }