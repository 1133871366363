.social{
	padding: 0px;
	margin: 0px;
	margin-top: 15px;
	list-style: none;
	li{
		display: inline-table;
		a{
			width: 40px;
			height: 40px;
			background-color: transparent;
			text-align: center;
			border-radius: 3px;
			margin-right:5px;
			display: inline-table;
			color: #fff;
			background-color: rgba(0,0,0,.5);
			i{
				line-height: 40px;
				font-size: 20px;
			}
			&:hover{
				background-color: $color-3st;
				i{
					color: #fff;
				}
			}
		}
		&:first-child{
			padding-right: 10px;
		}
	}
}