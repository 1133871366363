.header-top{
	padding: 10px 0px;
	background-color: $color-2st;
	font-size: rem-calc(12);
	.list{
		li{
			display: inline-block;
			float: left;
			line-height: 15px;
			padding-right: 20px;
			text-transform: uppercase;
			color: #ccc;
			a{
				color: #ccc;
				i{
					padding-left: 5px;
				}
				&:hover{
					color: #fff;
				}
			}
		}
	}
	.ct-topbar__list{display: inline-block;}
	.ct-language:not(ul){
		color: $color-2st;
	}
	.ct-language__dropdown{
		display: inline-table;
		li{
			display: inline-table;
			a{
				color: #ccc;
				line-height: 15px;
				padding-right: 15px;
				text-transform: uppercase;
			}
		}
	}
	.list-right{
		li{
			display: inline-block;
			float: left;
			line-height: 15px;
			padding: 0px 15px;
			border-right: 1px solid rgba(255,255,255,.5);
			text-transform: uppercase;
			a{
				color: #ccc;
				&:hover{
					color: #fff;
				}
			}
			&:last-child{
				padding-right: 0px;
				border-right: 0px;
			}
		}
	}
}
@media(max-width: 576px){
	.header-top{
		.list{
			li{
				&:last-child{
					display: none;
				}
			}
		}
	}
}
@media(max-width: 991px){
	.header-top{
		display: block;
		position: fixed;
		z-index: 555;
		left: 0;
		right: 0;
		top:0;
		background-color: $color-2st;
		display: flex;
		padding: 0px;
		.list{
			li{
				a{
					line-height: 45px;
				}
			}
		}
		.mobile_button{
			.symbol_menu{
				float: right;
				color: #fff;
				font-size: rem-calc(24);
				line-height: 45px;
			}
		}
	}
}