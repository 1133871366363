.new-home{
	padding: 40px 0px;
}
//Tin ngang
.new-horizontal{
	display: flex;
	flex-wrap: wrap;
	&--item{
		width: 100%;
		float: left;
		color: #666;
		padding: 10px 0px;
		border-bottom: 1px solid #ccc;
		&__img{
			width: 200px;
			display: inline-block;
			float: left;
			img{
				width: 100%;
				max-width: 100%;
			}
		}
		&__content{
			float: left;
			width: 100%;
			width: calc(100% - 215px);
			margin-left: 15px;
			display: inline-block;
			@include headings{
				margin: 0px;
				a{
					color: $color-2st;
					font-weight: 500;
					font-size: rem-calc(15);
					text-transform: capitalize;
					&:hover{
						color: $color-3st;
					}
				}
				.date{
					color: #888;
					font-weight: 400;
					font-size: rem-calc(14);
					padding-left: 5px;
					font-style: italic;
				}
			}
			.desc{
				display: inline-table;
				font-size: rem-calc(13);
				color: #888;
				width: 100%;
				padding-top: 5px;
			}
		}
	}
}


//Tin dọc
.new-vertical{
	display: flex;
	flex-wrap: wrap;
	&--item{
		width: 33.33%;
		width: calc(33.33% - 20px);
		margin-right: 30px;
		display: inline-block;
		background-color: #fff;
		border-radius: 5px;
		margin-bottom: 15px;
		box-shadow: 0px 1px 3px 1px #c7c8ca;
		&__img{
			width: 100%;
			display: inline-block;
			position: relative;
			overflow: hidden;
			a{
				display: block;
				img{
					width: 100%;
					max-width: 100%;
					border-top-right-radius: 5px;
					border-top-left-radius: 5px;
				}
			}
		}
		&__content{
			width: 100%;
			width: calc(100% - 0px);
			display: inline-block;
			padding: 0px 15px;
			@include headings{
				padding: 5px 0px;
				padding-top: 15px;
				margin: 0px;
				font-size: rem-calc(18);
				font-weight: $font-regular;
				display: inline-block;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				a{
					color: $color-2st;
					&:hover{
						color: $color-1st;
					}
				}
			}
			.desc{
				text-transform: lowercase;
				padding-bottom: 10px;
			}
		}
		
		&:hover{
			img{
				// -webkit-transform: rotate(0) scale(1);
				// transform: rotate(0) scale(1);
			}
		}
		&:nth-child(3n + 3){
			margin-right: 0px;
		}
	}
}

@media (max-width: 767px){
	.new-vertical{
		&--item{
			width: 100%;
			width: calc(100% - 0px);
			margin-right: 0px;
		}
	}

	.new-horizontal{
		&--item{
			width: 100%;
			margin-bottom: 15px;
			margin-right: 0px;
			padding: 0px;
			&__img{
				width: 80px;
			}
			&__content{
				width: 100%;
				width: calc(100% - 90px);
				padding-top: 0px;
				margin-left: 10px;
				.desc{
					display: none;
				}
			}
		}
	}
	@media (min-width: 576px){

	}
}

@media(min-width: 992px){
	.new-vertical{
		&--item{
			&__img{
				img{
					// height: 170px;
				}
			}
		}
	}

	.ly{
		.new-vertical{
			&--item{
				width: 25%;
				width: calc(25% - 22.5px);
				margin-right: 30px;
				margin-bottom: 30px;
				border: 1px solid #ddd;
				&:nth-child(4n + 4){
					margin-right: 0px;
				}
			}
		}
	}
	
}


//tin nhỏ ngang
.newsmall-horizontal{
	padding: 15px 0px;
	&--item{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 5px;
		margin-bottom: 10px;
		&__img{
			width: 80px;
			display: inline-block;
			img{
				width: 100%;
				max-width: 100%;
				border-radius: 3px;
			}
		}
		&__content{
			width: 100%;
			width: calc(100% - 95px);
			margin-left: 15px;
			display: inline-block;
			a{
				font-size: rem-calc(15);
				color: $color-2st;
				&:hover{
					color: $color-3st;
					text-decoration: underline;
				}
			}
			.date{
				color: #72adec;
				font-size: rem-calc(13);
			}
		}
		&:last-child(){
			border-bottom: none;
		}
	}
}

.list-tin{
	padding: 0px;
	margin: 0px;
	margin-bottom: 10px;
	list-style: none;
	li{
		display: inline-block;
		width: 100%;
		padding-bottom: 5px;
		._img{
			width: 100%;
			margin-bottom: 10px;
			display: inline-block;
			img{
				width: 100%;
			}
		}
		.link{
			font-size: rem-calc(13.5);
			color: $color-2st;
			position: relative;
			padding-left: 15px;
			font-weight: 400;
			display: inline;
			&:before{
				content: "\f0da";
				position: absolute;
				left: 0px;
				font-family: fontAwesome;
				color: $color-3st;
			}
			&:hover{
				color: $color-3st;
			}
		}
		.date{
			color: #999;
			font-size: rem-calc(12);
			font-style: italic;
			padding-left: 5px;
			display: inline-table;
		}
	}
}




.other-new{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			line-height: 1.7;
			a{
				display: block;
				text-decoration: none;
				font-size: rem-calc(14);
				color: $color-2st;
				position: relative;
				padding: 3px 0px;
				padding-left: 15px;
				display: inline-block;
				text-transform: capitalize;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					left: 0px;
					top: 12px;
					width: 5px;
					height: 5px;
					background-color: rgba(0,0,0,.5);
					border-radius: 2px;
				}
				&:hover{
					color: $color-3st;
				}
			}
		}
	}