.header-mid{
	background-color: #b9cfd7;
	padding: 15px 0px;
	position: relative;
	.logo{
		img{
			max-width: 100%;
		}
	}
	&:before{
		content: "";
		position: absolute;
		left: 0px;
		width: 100%;
		height: 200px;
		background-color:#b9cfd7;
		bottom: -200px; 
		z-index: -1;
	}
}

@media (max-width: 767px){
	.header-mid{
		&:before{
			display: none;
		}
	}
	@media (min-width: 576px){
	}
}

// @media (min-width: 768px){
// 	@media (max-width: 991px){
// 	}
// }

// @media (min-width: 992px){
// }
// @media (min-width: 1200px){
// }