.bottom-footer{
	padding: 15px 0px;
	color: #333;
	font-size: rem-calc(13);
	.wrap{
		border-top: 1px solid #ccc;
		padding-top: 15px;
		a{
			color: $color-2st;
			&:hover{
				color: $color-3st;
			}
		}
	}
}
@media(max-width: 991px){
	.bottom-footer{
		text-align: center;
		.text-right{
			text-align: center !important;
		}
	}
}
