.video-home{
	margin-top: 10px;
	.wrap-video{
		display: inline-block;
		width: 100%;
		background-color: $color-2st;
		padding: 15px 15px;
		.tit{
			color: #fff;
			display: inline-block;
			width: 100%;
			// border-bottom: 2px solid rgba(255,255,255,.3);
			margin-bottom: 15px;
			// padding-bottom: 10px;
			font-size: rem-calc(18);
			font-weight: $font-bold;
			text-transform: uppercase;
			a{
				color: #fff;
			}
		}
	}
}
@media(max-width: 767px){
	.video-home{
		.wrap-video{
			.tit{
				margin-top: 30px;
			}
		}
	}
}