header{
	// box-shadow: 0px 0px 5px 0px rgba(145, 145, 145, 0.75);
	// z-index: 100;
	background-color: transparent;
	position: relative;
	ul{
		list-style: none;
		padding: 0px;
		margin: 0px;
	}
}


@media (max-width: 991px){
	.header-main .register-advisory{
		display: none !important;
	}
	@media (max-width: 767px){
		header{
			padding-top: 45px;
		}
		.header-bar{
			display: none;
		}
	}
}

@media (min-width: 768px){
}

@media (min-width: 992px){
	.button-res{
		display: none;
	}
}

@media (min-width: 1200px){
	
}