footer{
	background-color: transparent;
	color: #000;
}
.top-footer{
	padding: 15px 0px;
	padding-bottom: 0px;
	.tit-footer{
		font-family: 'Roboto Condensed', sans-serif;
		text-transform: uppercase;
		font-size: rem-calc(18);
		display: inline-block;
		width: 100%;
		font-weight: 700;
		color: $color-3st;
		margin-bottom: 15px;
		position: relative;
	}
	.list-footer{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			line-height: 1.7;
			a{
				display: block;
				text-decoration: none;
				font-size: rem-calc(14);
				color: #333;
				position: relative;
				padding: 3px 0px;
				padding-left: 15px;
				display: inline-block;
				text-transform: capitalize;
				position: relative;
				&:before{
					content: "";
					position: absolute;
					left: 0px;
					top: 12px;
					width: 5px;
					height: 5px;
					background-color: rgba(0,0,0,.5);
					border-radius: 2px;
				}
				&:hover{
					color: $color-3st;
				}
			}
		}
	}
}
@media(max-width: 767px){
	.top-footer{
		.tit-footer{
			margin-top: 30px;
		}
		.order1{ order: 1; }
		.order2{ order: 2; }
		.order3{ order: 3; }
	}
}