//Custom variables

// Descriptive color variables

// Functional color variables
$color-1st : #333;
$color-2st : #262261;
$color-3st : #dd1e24;
$color-4st : #184e9e;
// Font stacks
// $font-1st : 'font-family: 'Roboto', sans-serif;';

$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$font-supperbold: 900;

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';
