//Tin dọc
.img{
	display: flex;
	flex-wrap: wrap;
	&--item{
		width: 100%;
		width: calc(100% - 0px);
		margin-right: 0px;
		display: inline-block;
		background-color: #fff;
		margin-bottom: 30px;
		overflow: hidden;
		position: relative;
		.tit{
			position: absolute;
	        content: "";
	        bottom: -10px;
	        left: 0px;
	        width: 100%;
	        display: inline-block;
	        padding: 30px 30px;
	        color: #fff;
	        font-size: rem-calc(20);
	        background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 3%, rgb(0, 0, 0) 100%) !important;
		}
		img{
			width: 100%;
			max-width: 100%;
			-webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
		}
		&:hover{
			img{
				-webkit-transform: scale(1.1);
            	transform: scale(1.1);
			}
		}
	}
}

@media(min-width: 992px){
	.img{
		&--item{
			width: 50%;
			width: calc(50% - 15px);
			margin-right: 30px;
			&:nth-child(2n + 2){
				margin-right: 0px;
			}
		}
	}
}