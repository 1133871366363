.sidebarBox{
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 15px;
	margin-bottom: 15px;
	border: 2px solid #ddd;
	// box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	.tit{
		display: inline-block;
		width: 100%;
		font-size: rem-calc(18);
		font-weight: 500;
		padding-bottom: 15px;
	}
	.dktv{
		.form-control{
			border-radius: 5px;
			padding: 8px 15px;
			background-color: #f4f4f4;
			border: none;
			outline: none;
			&:focus{
				outline: none !important;
				border: 1px solid $color-3st;
			}
		}
	}

	.link-video{
		display: inline-block;
		width: 100%;
		background-color: $color-4st;
		padding: 10px 15px;
		border-radius: 5px;
		text-align: center;
		margin-top: 15px;
		color: #fff;
	}
}

@media (max-width: 767px){
	
	@media (min-width: 576px){

	}
}

@media (min-width: 768px){
	.fix-left{
		position: fixed;
	    z-index: 9999;
	    width: 303px;
	    background-color: #fff;
	    border-color: $color-4st;
	    top: 100px !important;
	    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	}
}

@media (min-width: 992px){
	
}

@media (min-width: 1200px){
	
}

// /*Danh mục menu trái phải*/
// .top-pro-new{
// 	.cate-menu{
// 		list-style: none;
// 		padding: 0px;
// 		margin: 0px;
// 		background-color: $color-2st;
// 		li{
// 			position: relative;
// 			list-style: none;
// 			i{
// 			    display: inline-block;
// 			    text-align: center;
// 			    height: 42px;
// 			    width: 42px;
// 			    line-height: 42px;
// 			    color: #fff;
// 			    padding-top: 0px;
// 			    position: absolute;
// 			    top: 0px;
// 			    right: 0px;
// 			    border-left: 1px solid #fff;
// 			    // -ms-transform: rotate(90deg);
// 			    // -webkit-transform: rotate(90deg);
// 			    // transform: rotate(90deg);
// 			}
// 			a{
// 				display: block;
// 			    padding: 10px 15px;
// 			    padding-right: 5px;
// 			    color: #fff;
// 			    text-transform: capitalize;
// 			}

// 			.mainmenu-sub{
// 				padding: 0px;
// 				margin: 0px;
// 				list-style: none;
// 				background-color: #eee;
// 				border: 1px solid #ddd;

// 				li{
// 					a{
// 						background-color: #fff;
// 						color: #333;
// 						border-bottom: 1px solid #ddd;
// 					}
// 					&:last-child a{
// 						border-bottom: 0px;
// 					}
					
// 				}
// 			}
// 		}
// 	}
// }