.title-main {
	border-bottom: 2px solid $color-2st;
	display: inline-block;
	width: 100%;
	margin-bottom: 12px;
	font-family: 'Roboto Condensed', sans-serif;
	@include headings{
		font-weight: 700;
		text-transform: uppercase;
		font-size: rem-calc(16);
		display: inline-block;
		color: $color-2st;
		position: relative;
		margin: 0px;
		padding-bottom: 5px;
		a{
			color: $color-2st;
			&:hover{
				color: $color-3st;
			}
		}
	}
	.viewmore{
		font-size: rem-calc(13);
		color: $color-3st;
		font-weight: $font-regular;
		&:hover{
			color: $color-1st;
		}
	}
}
.video-home{
	width: 100%;
	position: relative;
	&:before{
		width: 50px;
		height: 50px;
		background-color: #f5ab3d;
		opacity: .9;
		border-radius: 100%;
		content: "\f04b";
		position: absolute;
		left: 50%;
		margin-left: -25px;
		top: 50%;
		margin-top: -25px;
		font-family: fontAwesome;
		color: #fff;
		text-align: center;
		line-height: 50px;
		font-size: 20px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
	}
	&:hover{
		&:before{
			opacity: 1;
		}
	}
}
.title-sidebar{
	font-family: 'Roboto Condensed', sans-serif;
	text-align: left;
	@include headings{
		font-weight: bold;
		text-transform: uppercase;
		font-size: rem-calc(20);
		padding: 15px 0px;
		padding-left: 15px;
		display: inline-block;
		position: relative;
		margin: 0px;
		color: #fff;
		background-color: $color-2st;
		display: inline-block;
		width: 100%;
	}
}
.share{
	display: inline-table;
	width: 100%;
	list-style: none;
	padding: 0px;
	margin: 0px;
	float: right;
	li{
		display: inline-table;
		a{
			width: 30px;
			height: 30px;
			color: #fff;
			background-color: #3553ad;
			line-height: 30px;
			text-align: center;
			margin-left: 5px;
			border-radius: 100%;
			display: inline-table;
			&:hover{
				background-color: $color-2st;
			}
		}
		&:last-child{
			a{
				background-color: #1697e0;
			}
		}
	}
}
.title-other{
	font-weight: $font-bold;
	text-transform: capitalize;
	font-size: rem-calc(18);
	display: inline-block;
	color: $color-1st;
	display: inline-block;
	width: 100%;
	margin: 15px 0px;
	margin-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}

.title-post{
	font-size: rem-calc(22);
	display: inline-block;
	width: 100%;
	// padding-bottom: 15px;
}

.intro-bound-both{
	display: inline-table;
	width: 100%;
	padding: 15px 10px;
	background-color: #d7dfe2;
	margin-bottom: 10px;
	._item{
		display: flex;
		color: $color-2st;
		width: 100%;
		padding: 15px 0px;
		i{
			font-size: rem-calc(32);
			width: 45px;
		}
		span{
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: $font-bold;
		    color: #58595b;
		    text-transform: uppercase;
		    font-size: rem-calc(14);
		}
		&:hover{
			color: $color-3st;
		}
	}

	.col-md-4{
		border-left: 1px solid rgba(0,0,0,.5);
		p{
			padding-bottom: 0px;
			a{
				color: $color-1st;
				text-transform: uppercase;
				font-size: rem-calc(13);
				i{
					color: $color-3st;
				}
				&:hover{
					color: $color-3st;
				}
			}
		}
	}
}
// @media(max-width: 767px){
// 	.title-main{
// 		margin-bottom: 30px;
// 		@include headings{
// 			font-size: rem-calc(24);
// 			text-transform: uppercase;
// 		}
// 	}
// 	.title-post{
// 		font-size: rem-calc(17);
// 	}
// 	@media(min-width: 576px){
// 	}
// }


