.fix-bottom{
	display: inline-block;
	width: 100%;
	background-color: transparent;
	position: fixed;
	bottom: 5px;
	left: 0px;
	z-index: 9999;
    font-size: rem-calc(14);
    text-align: center;
	a{
		color: #fff;
		display: inline-block;
		padding: 7px 15px;
		font-size: rem-calc(17);
		font-weight: $font-bold;
		background-color: #f00;
		border-radius: 30px;
	}
}
