.owl-carousel{
    .owl-nav{
        position: absolute;
        top: 50%;
        margin-top: -20px;
        left: 0;
        right: 0;
        .owl-prev, .owl-next {
            position: absolute;
            height: 40px;
            width: 40px;
            color: inherit;
            border: none;
            z-index: 100;
            background-color: transparent;
            display: block;
            opacity: 1;
            border-radius: 0px;
            outline: none;
            i{
                font-size: 32px;
                color: #333;
                line-height: 40px;
            }
        }
        .owl-prev {
            left: 30px;
        }
        .owl-next {
            right: 30px;
        }
    }
    &:hover .owl-prev{
        background-color: transparent !important;
        // opacity: 1;
        // display: block;
    }
    &:hover .owl-next{
        background-color: transparent !important;
        // opacity: 1;
        // display: block;
    }
}
