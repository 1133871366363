.btn-contact{
	display: inline-block;
	padding: 10px 30px;
	background-color: $color-2st;
	color: #fff;
	text-transform: uppercase;
	font-weight: $font-bold;
	outline: none;
	border: none;
	border-radius: 5px;
	&:hover{
		background-color: $color-3st;
	}
}
.btn-dktv{
	display: inline-block;
	color: #fff;
	line-height: 40px;
	// margin-bottom: -5px;
	background-color: $color-3st;
	text-transform: capitalize;
	position: relative;
	width: 100%;
	text-transform: uppercase;
	font-weight: $font-bold;
	font-size: rem-calc(17);
	text-align: center;
	&:hover{
		color: #fff;
	}
	&:before{
		border-right: 0 solid $color-3st;
	    border-left: 20px solid transparent;
	    border-top: 40px solid $color-3st;
	    content: "";
	    height: 0;
	    position: absolute;
	    left: -20px;
	    top: 0;
	    width: 0;
	}
	&:after{
	    content: "";
	    position: absolute;
	    right: 0px;
	    top: 0px;
	    margin-right: -300px;
	    width: 300px;
		height: 100%;
		background-color: $color-3st;
	}
}


.btn-border{
	display: inline-block;
	width: 100%;
	padding: 10px 10px;
	color: #fff;
	text-transform: uppercase;
	background-color: $color-2st;
	font-weight: $font-bold;
	outline: none;
	border-radius: 5px;
	border: 1px solid $color-2st;
	&:hover{
		background-color: #093d75;
	}
}