.main-footer{
    .luotruycap{
        padding: 0px;
        margin: 0px;
        list-style: none;
        border-left: 5px solid #ccc;
        padding-left: 15px;
        li{
            display: inline-block;
            width: 100%;
            font-size: rem-calc(14);
            color: #333;
        }
    }
}
