.mid-footer{
	.thongke{
		display: inline-table;
		width: 100%;
		padding: 0px;
		padding-left: 15px;
		margin: 0px;
		list-style: none;
		border-left: 3px solid rgba(0,0,0,.5);
	}
}