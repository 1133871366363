/****Menu danh mục fixed*/
.cate-menu{
    width: 100%;
    z-index: 8888;
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: inline-block;
    background-color: #d7dfe2;
    border: 1px solid $color-2st;
    font-family: 'Roboto Condensed', sans-serif;
    li{
    	width: 100%;
    	position: relative;
    	a{
    		color: $color-2st;
		    font-weight: 700;
		    font-size: 15px;
		    width: 100%;
		    padding: 16px 15px;
		    display: inline-block;
		    position: relative;
		    text-transform: uppercase;
		    position: relative;
		    border-bottom: 1px solid #ACB7BF;
		    border-left: 3px solid transparent;
		    span{
				font-size: rem-calc(16);
				background-color: #929497;
				color: #fff;
				padding: 5px 5px;
				float: right;
		    }
		    &:hover{
		    	color: $color-3st;
		    	text-decoration: none;
		    	border-left: 3px solid $color-3st;
		    }
    	}
    	&:hover .cate-menu-sub1{
    		opacity: 1;
    		visibility: visible;
    		display: block;
    	}
    	.cate-menu-sub1{
    		content: "";
    		position: absolute;
    		background-color: #f4f4f4;
    		left: 100%;
    		top: 0px;
    		width: 250px;
    		height: auto;
		    margin-left: 0;
		    z-index: 8888;
		    list-style: none;
		    padding: 0px;
		    webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
		    -moz-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
		    box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);

			opacity: 0;
			visibility: hidden;
			display: none;
		    li{
		    	width: 100%;
		    	a{
		    		font-family: Tahoma;
		    		border-bottom: none;
		    		font-size:rem-calc(14);
		    		text-transform: capitalize;
		    		font-weight: $font-regular;
		    		color: $color-1st;
		    		padding: 8px 15px; 
		    		padding-left: 20px;
		    		border-bottom: 1px solid #ccc;
		    		&:hover{
		    			color: $color-3st;
		    		}
		    		// &:before{
		    		// 	display: none;
		    		// }
		    	}
		    }
    	}
    	&:last-child{
    		a{
    			border-bottom: 1px solid #ddd;
    		}
    	}
    }
}
/*main menu*/
@media(min-width: 992px){
	ul.main-menu{
		width: 80%;
		float: left;
		margin: 0px;
		list-style: none;
		li{
			float: left;
			position: relative;
			a{
				color: $color-2st;
				font-size: rem-calc(16);
				text-transform: uppercase;
				display: inline-block;
				padding: 0px 15px;
				line-height: 45px;
				font-family: 'Roboto Condensed', sans-serif;
				font-weight: 700;
				position: relative;
				&:after{
					width: 1px;
					height: 25px;
					background-color: rgba(0,0,0,.2);
					content: "";
					position: absolute;
					right: 0px;
					top: 50%;
					margin-top: -12.5px;
				}
				.fa-caret-down{
					font-size: rem-calc(13);
					padding-left: 5px;
				}
				&:hover{
					color: $color-3st;
				}
			}
			&:hover .main-menu-sub1{
				opacity: 1;
				visibility: visible;
			}
			.main-menu-sub1{
				position: absolute;
				left: 0px;
				top: 100%;
				background-color: #fff;
				width: 220px;
				z-index: 999;
				display: block;
				opacity: 0;
				visibility: hidden;
				box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
				li{
					display: inline-block;
					position: relative;
					width: 100%;
					a{
						font-family: Tahoma, Arial, sans-serif;
						color: $color-1st;
						padding: 10px 15px;
						line-height: 18px !important;
						display: inline-block;
						width: 100%;
						font-weight: $font-regular;
						font-size: rem-calc(13);
						border-right: none;
						text-transform: none;
						border-bottom: 1px solid #eee;
						&:after{
							display: none;
						}
						&:hover{
							background-color: $color-3st;
							color: #fff;
						}
					}
					.main-menu-sub2{
						position: absolute;
						left: 100%;
						top: 0px;
						background-color: #fff;
						width: 220px;
						z-index: 999;
						display: block;
						opacity: 0;
						visibility: hidden;
						box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
					}
					&:hover .main-menu-sub2{
						opacity: 1;
						visibility: visible;
					}
					&:last-child(){
						a{
							border-bottom: none;
						}
					}
				}
			}
			&:last-child{
				a{
					border-right: 0;
					padding-right: 0px;
					&:after{
						display: none;
					}
				}
			}
			&.active{
				a{
					color: $color-3st;
				}
			}
		}
	}
}
@media (max-width: 991px){
	#menu{
	    position: fixed;
	    padding: 0;
	    -ms-transform: translateX(280px);
	    -webkit-transform: translateX(280px);
	    transform: translateX(280px);
	    background-color: $color-2st;
	    min-height: 100%;
	    height: 100vh;
	    z-index: 99999;
	    width: 280px;
	    right:0;
	    top: 0;
	    transition: all 0.3s ease;
	    opacity: 1;
	    overflow-x: scroll;
	}
	#menu ul {
	  	list-style: none;
	  	margin-top: 0;
	  	padding: 0;
	  	li{
	  		width: 100%;
		  	a{
		  		color: #fff;
			    display: inline-block;
			    width: 100%;
			    padding: 0px 15px;
			    padding-right: 50px;
			  	text-decoration: none;
			  	transition: all 0.3s ease;
			  	line-height: 40px;
    			font-weight: $font-bold;
    			border-bottom: 1px solid rgba(255,255,255,0.2);
    			text-transform: uppercase;
    			position: relative;
			    span{
			    	position: absolute;
				    width: 40px;
				    height: 40px;
				    background-color: transparent;
				    border-left: 1px solid rgba(255,255,255,.2);
				    text-align: center;
				    line-height: 40px;
				    right: 0px;
				    top: 0px;
			    }
			    &.active+ul{
					display: block;
			    }
		  	}
		  	ul{
		  		margin-top: 0;
		  		visibility: visible;
  				display: none;
  				position: relative;
  				opacity: 1;
  				left: 0px;
  				li{
  					border-bottom: none;
  					a{
  						color: #fff;
  						text-transform: capitalize;
  						font-weight: $font-regular;
  						line-height: 1.5;
  						padding: 10px 15px;
  						padding-left: 30px;
  						font-size: rem-calc(13);
  					}
  					ul{
  						display: block !important;
  						li{
  							a{
  								padding-left: 45px;
  								font-weight: $font-light;
  								border-left: 2px solid $color-3st;
  							}
  						}
  					}
  				}
		  	}
	  	}
	  	a{
	  		span[class*='fa-caret'] { float: right; }
	  		&.active,
	  		&:hover{
				background: rgba(0, 0, 0, 0.04);
	  		}
	  	}
	}
	.search{
		display: none;
	}

	.cate-menu{
		li{
			.cate-menu-sub1{
				position: static;
				width: 100%;
				box-shadow: none;
			}
		}
	}
	@media (max-width: 767px){
		.cate-menu{
			margin-bottom: 15px;
		}
		@media(min-width: 576px){
		}
	}
	@media (min-width: 768px){
		.header-main{
			padding: rem-calc(20) 0;
		}
	}
}

@media (min-width: 768px){
	.logo-mobile{
		display: none;
	}
}

@media (min-width: 992px){
	.logo-mb{
		display: none;
	}
	.header-main{
		padding: 0px 0px;
		#menu{
			width: 100%;
			background-color: #acb3b9;
			float: left;
			ul{
				margin: 0px;
				list-style: none;
			}
		}
		.search{
			width: 20%;
			width: calc(20% - 15px);
			margin-right: 15px;
			position: relative;
		    border-radius: 0px;
		    border: none;
		    background: #fff;
		    height: 35px;
		    margin-top: 5px;
		    display: inline-block;
		    input{
			    width: calc(100% - 70px);
			    background: transparent;
			    margin: 0;
			    padding: 0px 12px;
			    line-height: 35px;
			    height: 35px;
			    padding-left: 15px;
			    color: #333;
			    border: 0 none;
			    outline: 0px;
			}
			.search-button{
			    position: absolute;
			    top: 0;
			    right: 0px;
			    height: 35px;
			    padding: 0;
			    width: 40px;
			    font-size: 14px;
			    color: #666;
			    margin: 0;
			    outline: none;
			    border: 0 none;
			    background-color: #fff;
			    i{
				    transform: rotate(0deg) translate(0px, 0px);
				    transition: all 0.4s ease;
				    color: $color-2st;
				    font-size: 20px;
				}
			}
		}

	}
	.fixed-nav{
		position: fixed;
	    left: 0px;
	    right: 0px;
	    z-index: 9999;
	    top: 0px;
	    background-color: $color-2st;
	    #menu{
	    	background-color: transparent;
	    	ul{
	    		li{
	    			a{
	    				color: #fff;
	    			}
	    			ul{
	    				li{
	    					a{
	    						color: $color-1st;
	    					}
	    				}
	    			}
	    		}
	    	}
	    }
	}
}
// @media (min-width: 1200px){
// 	ul.main-menu{
// 		width: 80%;
// 		float: left;
// 		li{
// 			a{
// 				font-size: rem-calc(16);
// 				padding: 0px 15px;
// 			}
// 		}
// 	}
// }